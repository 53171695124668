import { RichTextInput } from "ra-input-rich-text";
import {
  DateTimeInput,
  Edit,
  required,
  SimpleForm,
  TextInput,
} from "react-admin";

const PluginTagEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <RichTextInput source="description" toolbar={[]} />
        <DateTimeInput source="updated_at" />
        <DateTimeInput source="created_at" />
      </SimpleForm>
    </Edit>
  );
};

export default PluginTagEdit;
