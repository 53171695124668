import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import ChevronRight from "@mui/icons-material/ChevronRight";
import {
  Datagrid,
  ShowButton,
  List,
  TextField,
  useListContext,
} from "react-admin";
import PostSearchInput from "../PostSearchInput";

const PostPagination = () => {
  const { page, perPage, total, setPage, resource } = useListContext();
  const nbPages = Math.ceil(total / perPage) || 1;
  const sessionStorageCursor = sessionStorage.getItem(
    `next_cursor_${resource}`
  );
  if (!sessionStorageCursor) {
    return <></>;
  }

  return (
    nbPages > 1 && (
      <Toolbar>
        {page !== nbPages && (
          <Button color="primary" key="next" onClick={() => setPage(page + 1)}>
            Load More
            <ChevronRight />
          </Button>
        )}
      </Toolbar>
    )
  );
};

const ReportList = (props) => {
  return (
    <List
      {...props}
      pagination={<PostPagination />}
      filters={<PostSearchInput source="search" />}
    >
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false} />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default ReportList;
