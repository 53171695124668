import { Create, required, SimpleForm, TextInput } from "react-admin";
const PluginTypeCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <TextInput source="description" toolbar={[]} />
      </SimpleForm>
    </Create>
  );
};

export default PluginTypeCreate;
