import { Admin, Resource } from "react-admin";
import AuthProvider from "./components/AuthProvider";
import dataProviders from "./components/dataProviders";
import PluginCreate from "./components/plugins/PluginCreate";
import PluginEdit from "./components/plugins/PluginEdit";
import PluginList from "./components/plugins/PluginList";
import PluginTagCreate from "./components/pluginsTags/PluginTagCreate";
import PluginTagEdit from "./components/pluginsTags/PluginTagEdit";
import PluginTagList from "./components/pluginsTags/PluginTagList";
import PluginTypeCreate from "./components/pluginsTypes/PluginTypeCreate";
import PluginTypeEdit from "./components/pluginsTypes/PluginTypeEdit";
import PluginTypeList from "./components/pluginsTypes/PluginTypeList";
import SoundCreate from "./components/sounds/SoundCreate";
import SoundEdit from "./components/sounds/SoundEdit";
import SoundList from "./components/sounds/SoundList";
import TagCreate from "./components/sounds/TagCreate";
import TagEdit from "./components/sounds/TagEdit";
import TagList from "./components/sounds/TagList";
import ReportList from "./components/reports/ReportList";
import ReportShow from "./components/reports/ReportShow";

import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
const theme = createTheme();
const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Admin dataProvider={dataProviders} authProvider={AuthProvider}>
          <Resource
            name="sounds"
            list={SoundList}
            create={SoundCreate}
            edit={SoundEdit}
          />
          <Resource
            name="tags"
            list={TagList}
            options={{ label: "Sound Tags" }}
            create={TagCreate}
            edit={TagEdit}
          />
          <Resource
            name="plugins"
            list={PluginList}
            edit={PluginEdit}
            create={PluginCreate}
          />
          <Resource
            name="plugin-types"
            list={PluginTypeList}
            edit={PluginTypeEdit}
            create={PluginTypeCreate}
          />
          <Resource
            name="plugin-tags"
            list={PluginTagList}
            edit={PluginTagEdit}
            create={PluginTagCreate}
          />
          <Resource name="clips/reports" list={ReportList} show={ReportShow} />
        </Admin>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
export default App;
