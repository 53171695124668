import {
  AutocompleteArrayInput,
  Create,
  FileField,
  FileInput,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
} from "react-admin";
const SoundCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="description" />
        <TextInput source="meta" />
        <ReferenceArrayInput label="Taggings" source="tags" reference="tags">
          <AutocompleteArrayInput
            optionText="name"
            optionValue="name"
            translateChoice={false}
          />
        </ReferenceArrayInput>
        <FileInput source="files" label="Related files" multiple={false}>
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};

export default SoundCreate;
