import { Datagrid, EditButton, List, TextField } from "react-admin";
import DeleteButtonWithAuth from "../DeleteButtonWithAuth";
import PostSearchInput from "../PostSearchInput";

const SoundList = (props) => {
  return (
    <List {...props} filters={<PostSearchInput source="filter" />}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <EditButton basePath="/sounds" />
        <DeleteButtonWithAuth basePath="/sounds" {...props} />
      </Datagrid>
    </List>
  );
};

export default SoundList;
