import jwt_decode from "jwt-decode";
export default function CheckTokenTime() {
  let token = localStorage.getItem("token");
  if (!token) {
    return true;
  }
  let decodedToken = jwt_decode(token);

  let currentDate = new Date();

  return decodedToken.exp * 1000 >= currentDate.getTime();
}
