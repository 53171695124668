import { RichTextInput } from "ra-input-rich-text";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  DateTimeInput,
  Edit,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
} from "react-admin";

const PluginEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="cdn_url" fullWidth={true} />
        <TextInput source="name" validate={required()} />
        <TextInput source="npm_name" fullWidth={true} validate={required()} />
        <RichTextInput source="description" toolbar={[]} />
        <TextInput source="demo_url" fullWidth={true} />
        <TextInput source="img_url" fullWidth={true} />
        <TextInput source="repo_url" fullWidth={true} />
        <TextInput source="sandbox_url" fullWidth={true} />
        <DateTimeInput source="updated_at" />
        <DateTimeInput source="created_at" />
        <NumberInput source="stars" />
        <NumberInput source="views" />
        <ReferenceInput
          label="plugin_type_id"
          source="plugin_type_id"
          reference="plugin-types"
          validate={required()}
        >
          <AutocompleteInput
            optionText="name"
            optionValue="id"
            translateChoice={false}
          />
        </ReferenceInput>
        <ReferenceArrayInput
          label="Taggings"
          source="tags"
          reference="plugin-tags"
          format={(pluginTag) => {
            if (!pluginTag) {
              return;
            }
            for (let i = 0; i < pluginTag.length; i++) {
              if (typeof pluginTag[i] == "object") {
                pluginTag[i] = pluginTag[i].id;
              }
            }
            return pluginTag;
          }}
        >
          <AutocompleteArrayInput
            optionText="name"
            optionValue="id"
            translateChoice={false}
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default PluginEdit;
