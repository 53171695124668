import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import ChevronRight from "@mui/icons-material/ChevronRight";
import {
  Datagrid,
  DateField,
  EditButton,
  List,
  TextField,
  useListContext,
} from "react-admin";
import DeleteButtonWithAuth from "../DeleteButtonWithAuth";
import PostSearchInput from "../PostSearchInput";

const PostPagination = () => {
  const { page, perPage, total, setPage, resource } = useListContext();
  const nbPages = Math.ceil(total / perPage) || 1;
  const sessionStorageCursor = sessionStorage.getItem(
    `next_cursor_${resource}`
  );
  if (!sessionStorageCursor) {
    return <></>;
  }

  return (
    nbPages > 1 && (
      <Toolbar>
        {page !== nbPages && (
          <Button color="primary" key="next" onClick={() => setPage(page + 1)}>
            Load More
            <ChevronRight />
          </Button>
        )}
      </Toolbar>
    )
  );
};

const PluginList = (props) => {
  return (
    <List
      {...props}
      pagination={<PostPagination />}
      filters={<PostSearchInput source="search" />}
    >
      <Datagrid>
        <TextField source="id" sortable={false} />
        <TextField source="name" sortable={false} />
        <DateField source="updated_at" />
        <DateField source="created_at" />
        <EditButton basePath="/plugins" />
        <DeleteButtonWithAuth basePath="/plugins" {...props} />
      </Datagrid>
    </List>
  );
};

export default PluginList;
