import { Datagrid, EditButton, List, TextField } from "react-admin";
import DeleteButtonWithAuth from "../DeleteButtonWithAuth";

const PluginTypeList = (props) => {
  return (
    <List {...props} pagination={<></>}>
      <Datagrid>
        <TextField source="id" sortable={false} />
        <TextField source="name" sortable={false} />
        <EditButton basePath="/plugin-types" />
        <DeleteButtonWithAuth basePath="/plugin-types" {...props} />
      </Datagrid>
    </List>
  );
};

export default PluginTypeList;
