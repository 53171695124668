import { Datagrid, EditButton, List, TextField } from "react-admin";
import DeleteButtonWithAuth from "../DeleteButtonWithAuth";
const TagList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="is_main" />
        <EditButton basePath="/tags" />
        <DeleteButtonWithAuth basePath="/tags" {...props} />
      </Datagrid>
    </List>
  );
};

export default TagList;
