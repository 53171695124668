import * as React from "react";
import { Show, SimpleShowLayout, TextField, DateField } from "react-admin";

const ReportShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="flags.name" label="Report Name" />
      <TextField source="user.nickname" label="Reporter Name" />
      <TextField source="user.email" label="Reporter Email" />
      <TextField source="clip_id" label="Reported Clip" />
      <TextField source="description" />
      <DateField source="created_at" />
    </SimpleShowLayout>
  </Show>
);

export default ReportShow;
