import { Datagrid, EditButton, List, TextField } from "react-admin";
import DeleteButtonWithAuth from "../DeleteButtonWithAuth";

const PluginTagList = (props) => {
  return (
    <List {...props} pagination={<></>}>
      <Datagrid>
        <TextField source="id" sortable={false} />
        <TextField source="name" sortable={false} />
        <EditButton basePath="/plugin-tags" />
        <DeleteButtonWithAuth basePath="/plugin-tags" {...props} />
      </Datagrid>
    </List>
  );
};

export default PluginTagList;
