import CheckTokenTime from "./CheckTokenTime";

const { REACT_APP_API_SOUND } = process.env;
const AuthProvider = {
  login: ({ username, password }) => {
    const request = new Request(`${REACT_APP_API_SOUND}/login`, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ token }) => localStorage.setItem("token", token.access_token));
  },
  logout: () => {
    const token = localStorage.getItem("token");
    const request = new Request(`${REACT_APP_API_SOUND}/logout`, {
      method: "POST",

      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    });
    return fetch(request).then(() => {
      return localStorage.removeItem("token");
    });
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("auth");
      return Promise.reject({ redirectTo: "/login" });
    }
    return Promise.resolve();
  },
  getPermissions: () => Promise.resolve(),
  checkAuth: () => {
    if (!localStorage.getItem("token") || !CheckTokenTime()) {
      return Promise.reject({ redirectTo: "/login" });
    }
    return Promise.resolve();
  },
};

export default AuthProvider;
