import {
  AutocompleteArrayInput,
  Edit,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
} from "react-admin";

const SoundEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="description" />
        <TextInput source="Meta" />
        <ReferenceArrayInput
          label="Taggings"
          source="tags"
          reference="tags"
          format={(v) => {
            for (let i = 0; i < v.length; i++) {
              if (typeof v[i] == "object") {
                v[i] = v[i].id;
              }
            }

            return v;
          }}
        >
          <AutocompleteArrayInput
            optionText="name"
            optionValue="name"
            translateChoice={false}
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default SoundEdit;
