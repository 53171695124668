import { useEffect } from "react";
import { DeleteButton, useRedirect } from "react-admin";
import CheckTokenTime from "./CheckTokenTime";

const DeleteButtonWithAuth = (props) => {
  const redirect = useRedirect();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      redirect("/login");
    }
    if (!CheckTokenTime()) {
      redirect("/login");
    }
  }, [redirect]);
  return <DeleteButton {...props} />;
};

export default DeleteButtonWithAuth;
