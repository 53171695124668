import { useEffect } from "react";
import { Filter, SearchInput } from "react-admin";

const PostSearchInput = (props) => {
  useEffect(() => {
    sessionStorage.clear();
  }, []);
  return (
    <Filter {...props}>
      <SearchInput source={props.source} resettable alwaysOn />
    </Filter>
  );
};

export default PostSearchInput;
