import {
  AutocompleteArrayInput,
  AutocompleteInput,
  Create,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
} from "react-admin";
const PluginCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="cdn_url" fullWidth={true} />
        <TextInput source="name" validate={required()} />
        <TextInput source="npm_name" fullWidth={true} validate={required()} />
        <TextInput source="description" toolbar={[]} />
        <TextInput source="demo_url" fullWidth={true} />
        <TextInput source="img_url" fullWidth={true} />
        <TextInput source="repo_url" fullWidth={true} />
        <TextInput source="sandbox_url" fullWidth={true} />
        <ReferenceInput
          label="plugin_type_id"
          source="plugin_type_id"
          reference="plugin-types"
          validate={required()}
        >
          <AutocompleteInput
            optionText="name"
            optionValue="id"
            translateChoice={false}
          />
        </ReferenceInput>
        <ReferenceArrayInput
          label="tag_ids"
          source="tag_ids"
          reference="plugin-tags"
        >
          <AutocompleteArrayInput
            optionText="name"
            optionValue="id"
            translateChoice={false}
          />
        </ReferenceArrayInput>
        <NumberInput source="stars" />
        <NumberInput source="views" />
      </SimpleForm>
    </Create>
  );
};

export default PluginCreate;
