/* TODO remove eslint-disable-next-line */
import { stringify } from "query-string";
import restProvider from "ra-data-simple-rest";
import {
  CREATE,
  DELETE,
  fetchUtils,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
  UPDATE_MANY,
} from "react-admin";

const { REACT_APP_API_SOUND, REACT_APP_API_DC } = process.env;

const renameKey = (oldProp, newProp, { [oldProp]: old, ...others }) => ({
  [newProp]: old,
  ...others,
});

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};
const provider = restProvider(`${REACT_APP_API_SOUND}`, httpClient);
const provClone = {
  ...provider,
  getList: (resource, resourceParams) => {
    return provider
      .getList(resource, resourceParams)
      .then((res) => ({ ...res, data: res.data[resource] }));
  },
  getMany: (resource, resourceParams) => {
    return provider
      .getMany(resource, resourceParams)
      .then((res) => ({ ...res, data: res.data[resource] }));
  },
  create: (resource, params) => {
    let id = "";
    if (resource !== "sounds" || !params.data.files) {
      return httpClient(`${REACT_APP_API_SOUND}/${resource}`, {
        method: "POST",
        body: JSON.stringify(params.data),
      }).then((res) => {
        return { ...res, data: res.json.data };
      });
    }
    const soundList = params.data.files.rawFile.name.split(".");
    const soundType = soundList[soundList.length - 1];
    let formData = new FormData();
    formData.append("file", params.data.files.rawFile);
    const au = document.createElement("audio");
    let promise = new Promise(function (resolve) {
      function handleUpload(event) {
        au.removeEventListener("loadedmetadata", handleUpload);
        resolve(event);
      }
      au.src = params.data.files.src;
      au.addEventListener("loadedmetadata", handleUpload, false);
    });
    return promise
      .then((event) => {
        const res = Math.ceil(event.path[0].duration * 1000);
        params.data.name = `${params.data.name}_${res}`;
        delete params.data.files;
        return httpClient(`${REACT_APP_API_SOUND}/${resource}/upload`, {
          method: "POST",
          body: JSON.stringify({ ...params.data, file_type: soundType }),
        });
      })
      .then((res) => {
        if (!res.error) {
          id = res.json.id;
          return { ...res, data: res.json };
        }
        return res.error;
      })
      .then((res) => {
        if (res.error) {
          return res;
        }
        return fetchUtils.fetchJson(res.json.url, {
          method: "PUT",
          body: formData,
          headers: new Headers({
            "x-amz-acl": "public-read",
            "Access-Control-Allow-Origin": "*",
          }),
        });
      })
      .then((res) => {
        if (!res.error) {
          return httpClient(`${REACT_APP_API_SOUND}/${resource}/save`, {
            method: "POST",
            body: JSON.stringify({
              ...params.data,
              url: `https://sounds.ams3.digitaloceanspaces.com/sounds_storage/public/${params.data.name}.${soundType}`,
              id: id,
            }),
          });
        }
        return httpClient(`${REACT_APP_API_SOUND}/${resource}/save`, {
          method: "POST",
          body: JSON.stringify({
            ...params.data,
            abort: true,
          }),
        });
      })
      .then((res) => {
        return { ...res, data: res.json };
      });
  },
};

const plugingProvider = restProvider(`${REACT_APP_API_DC}`, httpClient);

const plugingProviderClone = {
  ...plugingProvider,
  getList: (resource, params) => {
    const { field, order } = params.sort;
    const sessionStorageKey = `next_cursor_${resource}`;
    let cursor =
      sessionStorage.getItem(sessionStorageKey) === "undefined"
        ? ""
        : sessionStorage.getItem(sessionStorageKey);

    const query = {
      order_by: field === "id" ? "" : field,
      order_direction: order.toLowerCase(),
      limit: 10,
      search: params.filter?.search,
      cursor: cursor,
    };
    const url = `${REACT_APP_API_DC}/${resource}?${stringify(query)}`;
    const options = {};

    return fetchUtils.fetchJson(url, options).then(({ json }) => {
      sessionStorage.setItem(
        sessionStorageKey,
        json.pagination?.next_cursor || ""
      );

      if (json.data === null) {
        return {
          data: [],
          total: 0,
        };
      }
      return {
        data: json.data,
        total: Number.MAX_SAFE_INTEGER,
      };
    });
  },
  getOne: (resource, params) =>
    fetchUtils
      .fetchJson(`${REACT_APP_API_DC}/${resource}/${params.id}`)
      .then(({ json }) => ({
        data: json.data,
      })),
  create: (resource, params) => {
    return httpClient(`${REACT_APP_API_DC}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => json);
  },
  getMany: (resource, resourceParams) => {
    return plugingProvider.getMany(resource, resourceParams).then((res) => {
      return { ...res.data };
    });
  },
  update: (resource, params) => {
    return httpClient(`${REACT_APP_API_DC}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(renameKey("tags", "tag_ids", params.data)),
    }).then(({ json }) => {
      return json;
    });
  },
};
const reportProviderClone = {
  ...plugingProvider,
  getList: (resource) => {
    const url = `${REACT_APP_API_DC}/${resource}`;
    return httpClient(url).then(({ json }) => {
      if (!json.data) {
        return {
          data: [],
          total: 0,
        };
      }
      return {
        data: json.data,
        total: Number.MAX_SAFE_INTEGER,
      };
    });
  },
  getOne: (resource, params) =>
    httpClient(`${REACT_APP_API_DC}/${resource}/${params.id}`).then(
      ({ json }) => ({
        data: json.data,
      })
    ),
};

const dataProvidersList = [
  { dataProvider: provClone, resources: ["sounds", "tags"] },
  {
    dataProvider: plugingProviderClone,
    resources: ["plugins", "plugin-types", "plugin-tags"],
  },
  {
    dataProvider: reportProviderClone,
    resources: ["clips/reports"],
  },
];

const dataProviders = (type, resource, params) => {
  const dataProviderMapping = dataProvidersList.find((dp) =>
    dp.resources.includes(resource)
  );
  const mappingType = {
    [GET_LIST]: "getList",
    [GET_ONE]: "getOne",
    [GET_MANY]: "getMany",
    [GET_MANY_REFERENCE]: "getManyReference",
    [CREATE]: "create",
    [UPDATE]: "update",
    [UPDATE_MANY]: "updateMany",
    [DELETE]: "delete",
  };
  return dataProviderMapping.dataProvider[mappingType[type]](resource, params);
};

export default dataProviders;
