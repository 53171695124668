import { BooleanInput, Edit, SimpleForm, TextInput } from "react-admin";
const TagEdit = (props) => {
  return (
    <Edit title="Edit Tag" {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <BooleanInput label="Is Main" source="is_main" />
      </SimpleForm>
    </Edit>
  );
};

export default TagEdit;
