import { BooleanInput, Create, SimpleForm, TextInput } from "react-admin";

const TagCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <BooleanInput label="Is Main" source="is_main" />
      </SimpleForm>
    </Create>
  );
};

export default TagCreate;
